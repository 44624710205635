<template>
  <v-container class="point-page">
    <div class="d-flex justify-space-between align-center rem-md-24 rem-6 primary--text mb-mb-14 mb-10">
      <img class="can-click" src="@/assets/img/icon-arrow-left.png" alt="arrow-left" @click="$router.go(-1)">
      {{ $t('pointHistory') }}
      <div></div>
    </div>

    <section class="point-list">
      <div v-for="(item, i) in history" :key="i" class="mb-12">
        <div class="d-flex justify-space-between rem-md-14 rem-6">
          <span>{{ item.order_time ? timestampToTime(item.order_time * 1000) : '' }}</span>
          <span>{{ item.product_name.includes('0x') ? '儲值' : '付款' }}</span>
        </div>

        <div class="d-flex justify-space-between rem-md-22 rem-8 mb-12">
          <span>{{ item.product_name.includes('0x') ? '儲值' : item.product_name }}</span>
          <span>{{ (item.price).toLocaleString() }}</span>
        </div>
        
        <hr class="line" v-if="i !== history.length - 1" />
      </div>
    </section>
  </v-container>
</template>

<script>
import base from '@/mixin/base'
export default {
  name: "Point",
  mixins: [base],
  data() {
    return {
      history: [],
    }
  },
  async mounted() {
    try {
      let result = await this.$store.dispatch('getPointHistory')
      if (result.status === 200){
        this.history = result.data || []
      }
    }catch (error){
      console.log('error', error)
    }
  }
}
</script>

<style lang="scss">
.point-page {
  .point-list {
    max-width: 630px;
    width: 100%;
    margin: 0 auto 100px;
  }

  .order-btn {
    width: 133px;
    height: 39px;
    font-size: 26px;
    @include dai_vuetify_md {
      width: 82px;
      height: 24px;
      font-size: 16px;
    }
  }
}
</style>